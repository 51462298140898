import * as React from "react"

import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Card from "../components/card"
const IosPage = ({ data }) => (
  <Layout>
    <Seo title="Homzhub Change-log" />
    <h3>Releases for iOS</h3>

    {data.allAirtable.edges.map(({ node }, i) => (
      <Card
        date={node.data.Release_Date}
        notes={node.data.Details.childMarkdownRemark.html}
        version={node.data.Version_No}
      />
    ))}
  </Layout>
)

export const query = graphql`
  query {
    allAirtable(
      sort: { fields: data___Release_Date, order: DESC }
      filter: { data: { Release_Medium: { eq: "iOS" } } }
    ) {
      edges {
        node {
          data {
            Release_Date(formatString: "MMM DD, YYYY")
            Version_No
            Release_Medium
            Details {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`

export default IosPage
